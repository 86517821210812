import React from "react"

import Title from "./Title"
import Download from "../assets/download"

const DownloadCard = props => {
  const { downloadPath, title } = props

  return (
    <a className="download-card" href={downloadPath} target='_blank' rel="noopener">
      <div className="download-card__image">
        <Download />
      </div>
      <Title as="h6" size="6" color="light">
        {title}
      </Title>
    </a>
  )
}

export default DownloadCard
