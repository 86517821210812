import React from "react"
import { useQuery } from "urql"

import DownloadCard from "../components/DownloadCard"
import Container from "../components/Container"
import Title from "../components/Title"
import Seo from "../components/seo"

const DocumentsPage = () => {
  const [{ data }] = useQuery({
    query: `
  query Documents {
    documents(last: 100) {
      id
      title
      file {
        url
      }
    }
  }
`,
  })

  return (
    <Container as="section" classes="documents">
      <Title as="h1" size="2" classes='documents__title'>
        Dokumenty do pobrania
      </Title>
      <div className="documents__wrapper--flex">
        {data?.documents.map(({ file, title, id }) => (
          <DownloadCard key={id} title={title} downloadPath={file.url} />
        ))}
      </div>
    </Container>
  )
}

export const Head = () => (
  <Seo
    title="Dokumenty"
    description="Szkoła Podstawowa im. Emilii Sczanieckiej we Lwówku."
  />
)

export default DocumentsPage
